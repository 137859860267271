import * as React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    DateInput,
} from 'react-admin';
import {
    required,
    maxLength,
} from 'react-admin';


const CashCreate = ({ ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="summary" label="Name" validate={[required(), maxLength(500)]} />
            <DateInput source="date" label="Datum" validate={required()} />
            <NumberInput source="value" />
        </SimpleForm>
    </Edit>
);

export default CashCreate;