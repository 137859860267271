import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    SearchInput,
    DateField,
    SelectField,
    SelectInput,
    SimpleList
} from 'react-admin';
import NoteEdit from "./noteEdit";
import { Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ ...props }: { label: string, source: string, defaultValue: any }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={(props.label)} />;
};

const NoteFilter: React.ReactElement<React.JSXElementConstructor<any>>[] = [
    <SearchInput source="summary" alwaysOn />,
    <SelectInput source="status" choices={[
        { id: 'open', name: 'Offen' },
        { id: 'in progress', name: 'in Bearbeitung' },
        { id: 'done', name: 'erledigt' },
        { id: 'information', name: 'Information' }]} />,
    <QuickFilter label="ToDo" source="status,nin" defaultValue="done,information" />,
];

const NoteList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={NoteFilter} filterDefaultValues={{ "status,nin": "done,information" }}>
            {isSmall ? (
                <SimpleList linkType="edit"
                    primaryText={record => `${record.summary}`}
                    secondaryText={record => `${record.status}`}
                    tertiaryText={record => new Intl.DateTimeFormat().format(new Date(record.due_date))}
                />
            ) : (
                <Datagrid expand={<NoteEdit />}>
                    <DateField source="due_date" label="Due-Date" />
                    <TextField source="summary" label="Titel" />
                    <SelectField source="status" choices={[
                        { id: 'open', name: 'Offen' },
                        { id: 'in progress', name: 'in Bearbeitung' },
                        { id: 'done', name: 'erledigt' },
                        { id: 'information', name: 'Information' }]} />
                    <DateField source="creation_date" label="Creation-Date" />
                </Datagrid>
            )}
        </List>
    )
}

export default NoteList;