import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';
import InstrumentCreate from './instrumentCreate';
import InstrumentEdit from './instrumentEdit';
import InstrumentList from './instrumentList';
import InstrumentShow from './instrumentShow';


const instruments_ressource = {
    list: InstrumentList,
    show: InstrumentShow,
    edit: InstrumentEdit,
    create: InstrumentCreate,
    icon: EmojiSymbolsIcon,
};

export default instruments_ressource;