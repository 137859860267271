import SchoolIcon from '@material-ui/icons/School';
import TrainerCreate from './trainerCreate';
import TrainerEdit from './trainerEdit';
import TrainerList from './trainerList';
import TrainerShow from './trainerShow';


const trainers_ressource = {
    list: TrainerList,
    show: TrainerShow,
    edit: TrainerEdit,
    create: TrainerCreate,
    icon: SchoolIcon,
};

export default trainers_ressource;