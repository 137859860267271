import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    ReferenceField,
    FunctionField,
    SimpleList,
    SearchInput,
    ReferenceInput,
    SelectInput,
    Pagination
} from 'react-admin';
import { Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ ...props }: { label: string, source: string, defaultValue: any }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={(props.label)} />;
};

const MusicanPagination = ({ ...props }) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const MusicanFilter: React.ReactElement<React.JSXElementConstructor<any>>[] = [
    <SearchInput source="name" alwaysOn />,
    <QuickFilter label="in Ausbildung" source="active" defaultValue={1} />,
    <QuickFilter label="JuKa" source="youth_band" defaultValue={1} />,
    <QuickFilter label="Aktive " source="normal_band" defaultValue={1} />,
    <ReferenceInput label="Instrument" source="instrument" reference="instruments">
        <SelectInput source="name" />
    </ReferenceInput >
];

const MusicanList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={MusicanFilter} filterDefaultValues={{ active: 1 }} pagination={<MusicanPagination/>}>
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => `${record.surname} ${record.name}`}
                    secondaryText={record =>
                    (
                        <ReferenceField basePath="trainers" record={record} source="trainer" reference="trainers" link={false}>
                            <FunctionField render={(record: any) => `${record.surname} ${record.name}`} />
                        </ReferenceField>
                    )}
                    tertiaryText={record =>
                    (
                        <ReferenceField basePath="instruments" record={record} source="instrument" reference="instruments" link={false}>
                            <FunctionField render={(record: any) => `${record.name}`} />
                        </ReferenceField>
                    )}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="surname" label="Vorname" />
                    <TextField source="name" label="Nachname" />
                    <DateField source="birthdate" />
                    <DateField source="start_date" />
                    <ReferenceField label="Instrument" source="instrument" reference="instruments" link={"show"}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Trainer" source="trainer" reference="trainers" link={"show"}>
                        <FunctionField render={(record: any) => `${record.surname} ${record.name}`} />
                    </ReferenceField>
                    <BooleanField source="youth_band" looseValue={true} />
                    <BooleanField source="normal_band" looseValue={true} />
                    <DateField source="d1_theory" />
                    <DateField source="d1_praxis" />
                    <DateField source="d2_theory" />
                    <DateField source="d2_praxis" />
                    <BooleanField source="active" looseValue={true} />
                </Datagrid>
            )}
        </List>
    )
}

export default MusicanList;