import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    SelectField,
    SearchInput,
    SimpleList
} from 'react-admin';
import { Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ ...props }: { label: string, source: string, defaultValue: any }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={(props.label)} />;
};

const InstrumentFilter: React.ReactElement<React.JSXElementConstructor<any>>[] = [
    <SearchInput source="name" alwaysOn />,
    <QuickFilter label="MVK-Eigentum" source="mvk_property" defaultValue={1} />,
];
const InstrumentList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={InstrumentFilter}>
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => `${record.name}`}
                    secondaryText={record =>`${record.condition}`}
                    tertiaryText={record =>
                    (
                        <BooleanField source="mvk_property" looseValue={true} label="MVK-Eigentum" />
                    )}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="name" label="Name/Typ" />
                    <SelectField source="condition" label="Zustand" choices={[
                        { id: 'bad', name: 'magelhaft' },
                        { id: 'poor', name: 'schlecht' },
                        { id: 'ok', name: 'akzeptabel' },
                        { id: 'good', name: 'gut' },
                        { id: 'very good', name: 'neuwertig' },
                    ]} />
                    <BooleanField source="mvk_property" looseValue={true} label="MVK-Eigentum" />
                    <DateField source="date_of_purchase" />
                </Datagrid>
            )}
        </List>
    )
}

export default InstrumentList;