import * as React from "react";
import {
    BooleanInput,
    Create,
    DateInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {
    required,
    minLength,
    maxLength,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(25)];



const InstrumentCreate = ({ ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" label="Name/Typ" validate={validateName} />
            <DateInput source="date_of_purchase" label="Kaufdatum/Alter" />
            <BooleanInput source="mvk_property" label="Eigentum MVK" validate={required()}/>
            <SelectInput source="condition" choices={[
                { id: 'bad', name: 'magelhaft' },
                { id: 'poor', name: 'schlecht' },
                { id: 'ok', name: 'akzeptabel' },
                { id: 'good', name: 'gut' },
                { id: 'very good', name: 'neuwertig' },
            ]} validate={required()} />
            <TextInput source="note" />
        </SimpleForm>
    </Create>
);

export default InstrumentCreate;