import * as React from "react";
import {
    Edit,
    TabbedForm,
    FormTab,
    TextInput,
    BooleanInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    Record,
} from 'react-admin';
import {
    required,
    minLength,
    maxLength,
    number,
    email,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(25)];
const validateEmail = email();
const validateNumber = number();

const MusicanEdit = ({ ...props }) => (
    <Edit {...props}>
        <TabbedForm >
            <FormTab label="Person">
                <TextInput source="surname" label="Nachname" validate={validateName}/>
                <TextInput source="name" label="Vorname" validate={validateName}/>
                <DateInput source="birthdate" label="Geburtstag" />
                <DateInput source="start_date" validate={required()}/>
                <BooleanInput source="active" validate={required()}/>
            </FormTab>
            <FormTab label="Music">
                <ReferenceInput label="Instrument" source="instrument" reference="instruments">
                    <AutocompleteInput source="name" />
                </ReferenceInput>
                <ReferenceInput label="Trainer" source="trainer" reference="trainers">
                    <AutocompleteInput source="name" optionText={(record: Record) => `${record.surname} ${record.name}`} />
                </ReferenceInput>
                <BooleanInput source="youth_band" />
                <BooleanInput source="normal_band" />
                <DateInput source="d1_theory" />
                <DateInput source="d1_praxis" />
                <DateInput source="d2_theory" />
                <DateInput source="d2_praxis" />
            </FormTab>
            <FormTab label="Details">
                <TextInput source="phone" validate={validateNumber}/>
                <TextInput source="mail" validate={validateEmail}/>
                <TextInput source="address" />
                <TextInput source="place" />
                <TextInput source="parent" />
                <TextInput source="note" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default MusicanEdit;