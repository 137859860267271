import * as React from "react";
import { Admin,Resource} from 'react-admin';
import basicAuthProvider from './authProvider';
import treeqlProvider from './dataProvider';
import MusicansResource from './musicans';
import TrainersResource from './trainers';
import InstrumentResource from './instruments';
import NotesResource from './notes';
import CashResource from './cash';
import {createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    secondary: {
      light: '#003466',
      main: '#003466',
      dark: '#003466',
      contrastText: '#fff',
    },
    primary: {
      light: '#003466',
      main: '#003466',
      dark: '#003466',
      contrastText: '#fff',
    },
  },
});

const dataProvider = treeqlProvider('/api.php');
const authProvider = basicAuthProvider();

const App = () => (
  <Admin theme={theme} authProvider={authProvider} dataProvider={dataProvider}>
      <Resource name="musicans" options={{ label: 'Jungmusiker' }} {...MusicansResource} />
      <Resource name="trainers" options={{ label: 'Ausbilder' }} {...TrainersResource}/>
      <Resource name="instruments" options={{ label: 'Instrumente' }} {...InstrumentResource}/>
      <Resource name="notes" options={{ label: 'Tasks' }} {...NotesResource}/>
      <Resource name="cash" options={{ label: 'Kasse'}} {...CashResource} />
      <Resource name="users"/>
  </Admin>
);
export default App;