import GroupIcon from '@material-ui/icons/Group';
import MusicanCreate from './musicanCreate';
import MusicanEdit from './musicanEdit';
import MusicanList from './musicanList';
import MusicanShow from './musicanShow';


const musicans_ressource = {
    list: MusicanList,
    show: MusicanShow,
    edit: MusicanEdit,
    create: MusicanCreate,
    icon: GroupIcon,
};

export default musicans_ressource;