import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
} from 'react-admin';
import {
    required,
    maxLength,
} from 'react-admin';


const CashCreate = ({ ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="summary" label="Name" validate={[required(), maxLength(500)]} />
            <NumberInput source="value" />
        </SimpleForm>
    </Create>
);

export default CashCreate;