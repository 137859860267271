import EuroIcon from '@material-ui/icons/Euro';
import CashCreate from './cashCreate';
import CashEdit from './cashEdit';
import CashList from './cashList';


const trainers_ressource = {
    list: CashList,
    create: CashCreate,
    edit: CashEdit,
    icon: EuroIcon,
};

export default trainers_ressource;