import * as React from "react";
import { 
    BooleanInput,
    Edit,
    DateInput,
    SelectInput,
    SimpleForm,
    TextInput, 
     } from 'react-admin';

  
const InstrumentEdit = ({...props}) => (
    <Edit {...props}>
            <SimpleForm>
                <TextInput source="name" label="Name/Typ" />
                <DateInput source="date_of_purchase" label="Kaufdatum/Alter" />
                <BooleanInput source="mvk_property" label="Eigentum MVK" />
                <SelectInput source="condition" choices={[
                    { id: 'bad',  name: 'magelhaft' },
                    { id: 'poor', name: 'schlecht' },
                    { id: 'ok',   name: 'akzeptabel' },
                    { id: 'good', name: 'gut' },
                    { id: 'very good', name: 'neuwertig' },
                ]} />
                <TextInput source="note" />          
            </SimpleForm>
    </Edit>
);

export default InstrumentEdit;