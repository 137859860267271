import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
} from 'react-admin';
import CashSum from "./cashSum";

const CashList = ({ ...props }) => (
    <>
    <List {...props} >
        <Datagrid rowClick="edit">
            <DateField source="date" label="Datum" />
            <TextField source="summary" label="Name" />
            <NumberField source="value" label="Betrag" />
        </Datagrid>
    </List>
    <CashSum />
    </>
)

export default CashList;