import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    SearchInput,
    TextInput,
    SimpleList
} from 'react-admin';
import { Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const TrainerFilter: React.ReactElement<React.JSXElementConstructor<any>>[] = [
    <SearchInput source="name" alwaysOn />,
    <TextInput source="instruments" />,
    <TextInput source="school" />
];

const TrainerList = ({ ...props }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={TrainerFilter}>
            {isSmall ? (
                <SimpleList linkType="show"
                    primaryText={record => `${record.surname} ${record.name}`}
                    secondaryText={record => `${record.instruments}`}
                    tertiaryText={record => `${record.school}`}
                />
            ) : (
                <Datagrid rowClick="show">
                    <TextField source="surname" label="Vorname" />
                    <TextField source="name" label="Nachname" />
                    <TextField source="phone" />
                    <EmailField source="mail" />
                    <TextField source="school" />
                    <TextField source="instruments" />
                </Datagrid>
            )}
        </List>
    )
}

export default TrainerList;