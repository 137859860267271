import * as React from "react";
import { Card, CardContent } from '@material-ui/core';
import { Title, useQueryWithStore, Record } from 'react-admin'


const sumZahlungen = (zahlungen: Record[]): number => {
    let total: number = 0;
    zahlungen.forEach(curr => {
        total += curr.value;
    });
    return total;
};

const CashSum = () => {
    const { loaded: cash_loaded, data: cash } = useQueryWithStore({
        type: 'getList',
        resource: 'cash',
        payload: {}
    });

    if (!cash_loaded) return null
    return (
            <Card>
                <Title title="Bilanz" />
                <CardContent>
                    Summe: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(sumZahlungen(cash))}
                </CardContent>
            </Card>
    )
};

export default CashSum;