import * as React from "react";
import {
    BooleanField,
    Show,
    SimpleShowLayout,
    DateField,
    SelectField,
    TextField,
    ReferenceManyField,
    SingleFieldList,
    FunctionField,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';

const InstrumentShow = ({ ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="Name/Typ" />
            <DateField source="date_of_purchase" label="Kaufdatum/Alter" />
            <BooleanField source="mvk_property" looseValue={true} label="Eigentum MVK" />
            <SelectField source="condition" choices={[
                { id: 'bad', name: 'magelhaft' },
                { id: 'poor', name: 'schlecht' },
                { id: 'ok', name: 'akzeptabel' },
                { id: 'good', name: 'gut' },
                { id: 'very good', name: 'neuwertig' },
            ]} />
            <ReferenceManyField label="used by" reference="musicans" target="instrument">
                <SingleFieldList linkType="show">
                    <FunctionField label="Name" render={(record: any) => <Chip label={`${record.surname} ${record.name}`}></Chip>} />
                </SingleFieldList>
            </ReferenceManyField>
            <TextField source="note" />
        </SimpleShowLayout>
    </Show>
);

export default InstrumentShow;