import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';
import {
    required,
    minLength,
    maxLength,
    number,
    email,
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(25)];
const validateEmail = email();
const validateNumber = number();

const TrainerCreate = ({ ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="surname" label="Nachname" validate={validateName} />
            <TextInput source="name" label="Vorname" validate={validateName}/>
            <TextInput source="school" label="Musikschule" />
            <TextInput source="phone" validate={validateNumber}/>
            <TextInput source="mail" validate={validateEmail}/>
            <TextInput source="instruments" />
            <TextInput source="notes" />
        </SimpleForm>
    </Create>
);

export default TrainerCreate;