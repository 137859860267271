import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    TextField,
    EmailField,
    ReferenceManyField,
    SingleFieldList,
    FunctionField,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';

const TrainerShow = ({ ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="surname" label="Nachname" />
            <TextField source="name" label="Vorname" />
            <TextField source="school" label="Musikschule" />
            <TextField source="phone" />
            <EmailField source="mail" />
            <TextField source="instruments" />
            <ReferenceManyField label="musicans" reference="musicans" target="trainer">
                <SingleFieldList linkType="show">
                    <FunctionField label="Name" render={(record: any) => <Chip label={`${record.surname} ${record.name}`}></Chip>} />
                </SingleFieldList>
            </ReferenceManyField>
            <TextField source="notes" />
        </SimpleShowLayout>
    </Show>
);

export default TrainerShow;