import * as React from "react";
import { 
    Show,
    TabbedShowLayout,
    Tab,  
    TextField, 
    BooleanField,
    DateField,
    ReferenceField,
    FunctionField,
     } from 'react-admin';

  
const MusicanShow = ({...props}) => (
    <Show {...props}>
        <TabbedShowLayout >
            <Tab label="Person">
                <TextField source="surname" label="Nachname" />
                <TextField source="name" label="Vorname" />
                <DateField source="birthdate" label="Geburtstag" />
                <DateField source="start_date" />
                <BooleanField source="active" />                
            </Tab>
            <Tab label="Music">
            <ReferenceField label="Instrument" source="instrument" reference="instruments" link={"show"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField label="Trainer" source="trainer" reference="trainers" link={"show"}>
                    <FunctionField render={(record:any) => `${record.surname} ${record.name}`}/>
                </ReferenceField>
                <BooleanField source="youth_band" />
                <BooleanField source="normal_band" />
                <DateField source="d1_theory" />
                <DateField source="d1_praxis" />
                <DateField source="d2_theory" />
                <DateField source="d2_praxis" />
            </Tab>
            <Tab label="Details">
                <TextField source="phone" />
                <TextField source="mail" />
                <TextField source="address" />
                <TextField source="place" />
                <TextField source="parent" />
                <TextField source="note" />
            </Tab> 
        </TabbedShowLayout>
    </Show>
);

export default MusicanShow;