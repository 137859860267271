import NoteIcon from '@material-ui/icons/Note';
import NoteCreate from './noteCreate';
import NoteEdit from './noteEdit';
import NoteList from './noteList';


const trainers_ressource = {
    list: NoteList,
    edit: NoteEdit,
    create: NoteCreate,
    icon: NoteIcon,
};

export default trainers_ressource;